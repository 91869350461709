class StaticHull {

    constructor(obj, savane, scene) {
        this.savane = savane;
        this.scene = scene;
        var loader;
        if (!scene.settings.hullbin) {
            loader = new SAVANE.OBJLoader();
            this.hull = loader.parse(obj);
        }
        else {
            loader = new SAVANE.BINLoader();
            this.hull = loader.parse(obj).object;
        }
        this.group = new THREE.Group();
        this.group.add(this.hull);
        this.mirrors = [];
        //updated entity
        this._entity = null;
        this._process();
        this.hideCeiling();
    }

    static create(obj, savane, scene) {
        return new StaticHull(obj, savane, scene);
    }

    cleanChild(child) {
        if (child.geometry) {
            child.geometry.dispose();
        }
        if (child.body) {
            this.scene.physics.destroy(child.body);
        }
        if (child.material) {
            Cleaner.cleanMaterial(child.material);
        }
        if (child.cubeCamera) {
            child.cubeCamera.renderTarget.dispose();
        }
    }

    dispose() {
        var traversing = function(child) {
            this.cleanChild(child);
        }
        for (var i = 0; i < this.mirrors.length; ++i) {
            this.mirrors[i].object.getRenderTarget().dispose();
            this.cleanChild(this.mirrors[i].object);
            this.mirrors[i].object.removeFromParent();
        }
        this.mirrors = [];
        this.hull.traverse(traversing.bind(this));
    }

    update(entity) {
        this._entity = entity;
        this.hull.traverse(this._processChild.bind(this));
        this._entity = null;
    }

    updateCoatingParameters(entity) {
        var traversing = function(child) {
            if (child.name.startsWith("Wall")) {
                var wallParse = child.name.split('_');
                var wallId = wallParse[1] - 0;

                var hangType = Savane.Coating.HangType.wallDirect;
                if (wallParse[3] === 'Indirect') {
                    hangType = Savane.Coating.HangType.wallUndirect;
                } else if (wallParse[2] === 'Top') {
                    hangType = Savane.Coating.HangType.wallTop;
                } else if (wallParse[2] === 'Bottom') {
                        hangType = Savane.Coating.HangType.wallBottom;
                } else if (wallParse[2] === 'RightSide') {
                    hangType = Savane.Coating.HangType.wallRightSide;
                } else if (wallParse[2] === 'LeftSide') {
                    hangType = Savane.Coating.HangType.wallLeftSide;
                }

                if (wallId === entity.id) {
                    var wall = this.savane.getDeepChild(wallId);
                    if (!wall) return;
                    var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                    for (var i = 0; i < coatings.length; i++) {
                        if (coatings[i].hangType === hangType) {
                            if (child.material.map) {
                                child.material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                child.material.map.offset.x = (coatings[i].offset[0]);
                                child.material.map.offset.y = (coatings[i].offset[1]);
                                child.material.map.repeat.x = child.material.map.originalRepeat.x * (coatings[i].repeat[0]);
                                child.material.map.repeat.y = child.material.map.originalRepeat.y * (coatings[i].repeat[1]);
                            }
                        }
                    }
                }
            }
            else if (child.name.indexOf("Ceiling") !== -1) {
                //add node to wall
                var ceilingParse = child.name.split('_');
                var index = 1;
                if (child.name.startsWith("axo")) {
                    index++;
                }
                var roomId = ceilingParse[index] - 0;

                if (roomId === entity.id) {
                    var room = this.savane.getDeepChild(roomId);

                    if (room !== null) {
                        var coatings = room.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                        if (coatings !== null) {
                            for (var i = 0; i < coatings.length; i++) {
                                if (coatings[i].hangType === Savane.Coating.HangType.ceiling) {
                                    if (child.material.map) {
                                        child.material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                        child.material.map.offset.x = (coatings[i].offset[0]);
                                        child.material.map.offset.y = (coatings[i].offset[1]);
                                        child.material.map.repeat.x = child.material.map.originalRepeat.x * (coatings[i].repeat[0]);
                                        child.material.map.repeat.y = child.material.map.originalRepeat.y * (coatings[i].repeat[1]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (child.name.startsWith("axo_Slope")) {
                var slopeParse = child.name.split('_');
                var wallId = slopeParse[2] - 0;

                if (wallId === entity.id) {
                    var directCoating = (slopeParse[3] === 'Direct');
                    var wall = this.savane.getDeepChild(wallId);

                    if (wall !== null) {
                        var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                        if (coatings !== null) {
                            for (var i = 0; i < coatings.length; i++) {
                                if ((coatings[i].hangType === Savane.Coating.HangType.slopeDirect && directCoating) || (coatings[i].hangType === Savane.Coating.HangType.slopeUndirect && !directCoating)) {
                                    if (child.material.map) {
                                        child.material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                        child.material.map.offset.x = (coatings[i].offset[0]);
                                        child.material.map.offset.y = (coatings[i].offset[1]);
                                        child.material.map.repeat.x = child.material.map.originalRepeat.x * (coatings[i].repeat[0]);
                                        child.material.map.repeat.y = child.material.map.originalRepeat.y * (coatings[i].repeat[1]);
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            else if (child.name.indexOf("Joinery") !== -1) {
                //add node to wall
                var joineryParse = child.name.split('_');

                var joineryId = joineryParse[2] - 0;

                if (joineryId === entity.id) {
                    var joinery = this.savane.getDeepChild(joineryId);

                    if (joinery !== null) {
                        var coatings = joinery.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                        if (coatings !== null) {
                            for (var i = 0; i < coatings.length; i++) {
                                if (Array.isArray(child.material)) {
                                    for (var j = 0 ; j < child.material.length ; j++) {
                                        if (child.material[j].map) {
                                            child.material[j].map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                            child.material[j].map.offset.x = (coatings[i].offset[0]);
                                            child.material[j].map.offset.y = (coatings[i].offset[1]);
                                            child.material[j].map.repeat.x = child.material[j].map.originalRepeat.x * (coatings[i].repeat[0]);
                                            child.material[j].map.repeat.y = child.material[j].map.originalRepeat.y * (coatings[i].repeat[1]);
                                        }
                                    }
                                }
                                else {
                                    if (child.material.map) {
                                        child.material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                        child.material.map.offset.x = (coatings[i].offset[0]);
                                        child.material.map.offset.y = (coatings[i].offset[1]);
                                        child.material.map.repeat.x = child.material.map.originalRepeat.x * (coatings[i].repeat[0]);
                                        child.material.map.repeat.y = child.material.map.originalRepeat.y * (coatings[i].repeat[1]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (child.name.indexOf("TechnicalElement") !== -1) {
                var techElementParse = child.name.split('_');
                var index = techElementParse.indexOf("TechnicalElement");

                if (index === -1) {
                    return;
                }

                var id = techElementParse[index + 2] - 0;

                if (id === entity.id) {
                    var techElement = this.savane.getDeepChild(id);
                    if (techElement) {
                        var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                        if (coatings !== null) {
                            for (var i = 0; i < coatings.length; i++) {
                                if (Array.isArray(child.material)) {
                                    for (var j = 0 ; j < child.material.length ; j++) {
                                        if (child.material[j].name === coatings[i].usemtlName) {
                                            if (child.material[j].map) {
                                                child.material[j].map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                                child.material[j].map.offset.x = (coatings[i].offset[0]);
                                                child.material[j].map.offset.y = (coatings[i].offset[1]);
                                                child.material[j].map.repeat.x = child.material[j].map.originalRepeat.x * (coatings[i].repeat[0]);
                                                child.material[j].map.repeat.y = child.material[j].map.originalRepeat.y * (coatings[i].repeat[1]);
                                            }
                                        }
                                    }
                                }
                                else {
                                    if (child.material.name === coatings[i].usemtlName) {
                                        if (child.material.map) {
                                            child.material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                            child.material.map.offset.x = (coatings[i].offset[0]);
                                            child.material.map.offset.y = (coatings[i].offset[1]);
                                            child.material.map.repeat.x = child.material.map.originalRepeat.x * (coatings[i].repeat[0]);
                                            child.material.map.repeat.y = child.material.map.originalRepeat.y * (coatings[i].repeat[1]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (child.name.indexOf("Stair") !== -1) {
                var stairElementParse = child.name.split('_');
                var index = stairElementParse.indexOf("Stair");

                if (index === -1) {
                    index = stairElementParse.indexOf("StairGuardsRail");
                    if (index === -1) {
                        return;
                    }
                }

                var id = stairElementParse[index + 1] - 0;

                if (id === entity.id) {
                    var stairElement = this.savane.getDeepChild(id);
                    if (stairElement) {
                        var coatings = stairElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                        if (coatings !== null) {
                            for (var i = 0; i < coatings.length; i++) {
                                if (Array.isArray(child.material)) {
                                    for (var j = 0 ; j < child.material.length ; j++) {
                                        if (child.material[j].name === coatings[i].usemtlName) {
                                            if (child.material[j].map) {
                                                child.material[j].map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                                child.material[j].map.offset.x = (coatings[i].offset[0]);
                                                child.material[j].map.offset.y = (coatings[i].offset[1]);
                                                child.material[j].map.repeat.x = child.material[j].map.originalRepeat.x * (coatings[i].repeat[0]);
                                                child.material[j].map.repeat.y = child.material[j].map.originalRepeat.y * (coatings[i].repeat[1]);
                                            }
                                        }
                                    }
                                }
                                else {
                                    if (child.material.name === coatings[i].usemtlName) {
                                        if (child.material.map) {
                                            child.material.map.rotation = (coatings[i].rotation * Math.PI) / 180;
                                            child.material.map.offset.x = (coatings[i].offset[0]);
                                            child.material.map.offset.y = (coatings[i].offset[1]);
                                            child.material.map.repeat.x = child.material.map.originalRepeat.x * (coatings[i].repeat[0]);
                                            child.material.map.repeat.y = child.material.map.originalRepeat.y * (coatings[i].repeat[1]);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        };

        this.hull.traverse(traversing.bind(this));
    }

    showCeiling() {
        var traversing = function(child) {
            if (child.name.startsWith("axo")) {
                if (child.name.startsWith("axo_Ceiling") || child.name.startsWith("axo_Slope") ||
                    child.name.startsWith("axo_Stair") || child.name.startsWith("axo_Tremie")) {
                    if (!this.scene.settings.interactiveProject) {
                        child.castShadow = true;
                    }
                    child.receiveShadow = true;
                }

                if (child.name.startsWith("axo_Ceiling")) {
                    var entityNb = child.name.split("_")[2] - 0;

                    if (entityNb < 0) {
                        child.material.side = THREE.FrontSide;
                    }
                }

                child.layers.set(0);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    hideCeiling() {
        var traversing = function(child) {
            // Ceiling always hidden
            var hide = false;
            if (child.name.startsWith("axo_TechnicalElement") ||
                child.name.startsWith("axo_Stair") || child.name.startsWith("axo_Tremie")) {
                hide = true;
            }
            if (child.name.startsWith("axo_Ceiling")) {
                var entityNb = child.name.split("_")[2] - 0;

                if (entityNb > 0) {
                    hide = true;
                }
                else  {
                    child.material.side = THREE.DoubleSide;
                }
            }

            // Hide only if top view camera (this.camera === null) hide slopes and axo_joineries (velux)
            //if (this.camera === null) {
            if (child.name.startsWith("axo_Slope") || child.name.startsWith("axo_Joinery") || child.name.startsWith("axo_Custom")) {
                hide = true;
            }
            //}

            if (hide === true) {
                child.castShadow = false;
                child.receiveShadow = false;
                child.layers.set(1);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    showUpperFloor(floor) {
        if (!floor) return;

        var traversing = function(child) {
            var entity = this.getEntityFromChild(child);
            if (entity && entity.floor && entity.floor.height > floor.height) {
                child.layers.set(0);
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    hideUpperFloor(floor) {
        var traversing = function(child) {
            var entity = this.getEntityFromChild(child);
            if (entity && entity.floor && entity.floor.height > floor.height) {
                child.layers.set(1);
            }
        }
        this.hull.traverse(traversing.bind(this));
    }

    hideWalls(excluded) {
        var traversing = function(child) {
            if (child.name.startsWith("Wall")) {
                var parse = child.name.split('_');
                var id = parse[1] - 0;
                var hide = true;
                for (var i = 0; i < excluded.length; ++i) {
                    if (excluded[i].id === id) {
                        hide = false;
                        break;
                    }
                }
                if (hide) {
                    child.layers.set(1);
                    if (Array.isArray(child.material)) {
                        for (var i = 0; i < child.material.length; ++i) {
                            child.layers.set(1);
                        }
                    } else {
                        child.layers.set(1);
                    }
                }
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    showWalls() {
        var traversing = function(child) {
            if (!child.name.startsWith("Wall")) {
                return;
            }

            child.layers.set(0);
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    child.layers.set(0);
                }
            } else {
                child.layers.set(0);
            }
        };
        this.hull.traverse(traversing.bind(this));
    }

    filterFloor(floor) {
        var traversing = function(child) {
            var entity = this.getEntityFromChild(child);
            if (floor !== null) {
                if (entity !== null && floor.getDeepChild(entity.id)) {
                    child.layers.set(0);
                } else {
                    child.layers.set(1);
                }
            } else {
                child.layers.set(0);
            }

        }
        this.hull.traverse(traversing.bind(this));
    }

    static getIdFromChild(child) {
        var result = null;

        if (child.name.startsWith("Wall")) {
            var parse = child.name.split('_');
            result = parse[1] - 0;
        } else if (child.name.startsWith("TechnicalElementPlinth")) {
            var parse = child.name.split('_');
            result = parse[2] - 0;
        } else if (child.name.indexOf("TechnicalElement") !== -1) {
            var parse = child.name.split('_');
            var index = parse.indexOf("TechnicalElement");
            result = parse[index + 2] - 0;
        } else if (child.name.startsWith("Floor") && !child.name.startsWith("FloorPlinth")) { // FloorPlinth is part of the FGHull
            var parse = child.name.split('_');
            result = parse[1] - 0;
        } else if (child.name.indexOf("Ceiling") !== -1) {
            var parse = child.name.split('_');
            var index = 1;
            if (child.name.startsWith("axo")) {
                index++;
            }
            result = parse[index] - 0;
        } else if (child.name.startsWith("Joinery") || child.name.startsWith("axo_Joinery")) {
            var parse = child.name.split('_');
            result = (child.name.startsWith("axo") ? parse[3] - 0 : parse[2] - 0);
        } else if (child.name.startsWith("Stair") || child.name.startsWith("axo_Stair")) {
            var parse = child.name.split('_');
            result =  (child.name.startsWith("axo") ? parse[2] - 0 : parse[1] - 0);
        } else if (child.name.startsWith("Plinth")) {
            var parse = child.name.split('_');
            result = parse[1] - 0;
        } else if (child.name.indexOf("axo_Cornice") !== -1) {
            var parse = child.name.split('_');
            result = parse[2] - 0;
        } else if (child.name.indexOf("Slope") !== -1) {
            var parse = child.name.split('_');
            result = parse[2] - 0;
        } else if (child.name.indexOf("DressingElement") !== -1) {
            var parse = child.name.split('_');
            result = parse[2] - 0;
        }

        return result ? Math.abs(result) : null;
    }

    getChildsFromId(id) {
        var result = [];
        var traversing = function(child) {
            if (id === StaticHull.getIdFromChild(child)) {
                result.push(child);
            }
        }
        this.hull.traverse(traversing.bind(this));
        return result;
    }

    getEntityFromChild(child) {
        var result = null;
        var id = StaticHull.getIdFromChild(child);
        if (id) {
            result = this.savane.getDeepChild(id);
            if (!result && child.name.indexOf("DressingElement") !== -1) {
                result = this.savane.getDeepChild(id - 1000);
            }
        }
        return result;
    }

    static replaceChildNameId(child, id) {
        if (child.name.indexOf("TechnicalElement") !== -1) {
            var parse = child.name.split('_');
            var index = parse.indexOf("TechnicalElement");
            parse[index + 2] = id;
            child.name = parse.join('_');
        } else if (child.name.startsWith("Stair")) {
            var parse = child.name.split('_');
            parse[1] = id;
            child.name = parse.join('_');
        } else if (child.name.startsWith("axo_Stair")) {
            var parse = child.name.split('_');
            parse[2] = id;
            child.name = parse.join('_');
        }
    }

    setCustomCoating(object, customCoating) {
        Material.setMaterialFromCoating(object, customCoating.coating, true, undefined, this.scene, true);
    }

    _process() {
        this.hull.traverse(this._processChild.bind(this));
    }

    _createCubeCamera(child, resolution) {
        if (child.cubeCamera) {
            child.cubeCamera.renderTarget.dispose();
        }
        var bbox = new THREE.Box3().setFromObject(child);
        var renderTarget = new THREE.WebGLCubeRenderTarget(resolution, {
            format: THREE.RGBAFormat,
            anisotropy: 2,
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter
        });
        child.cubeCamera = new THREE.CubeCamera(1, 1000, renderTarget);
        bbox.getCenter(child.cubeCamera.position);
    }

    _handleAxo(child, entity) {
        if (!child.name.startsWith("axo")) {
            return;
        }

        if (child.name.indexOf("Joinery") !== - 1) {
            return;
        }

        if (child.name.indexOf("TechnicalElement") !== - 1) {
            return;
        }

        if (child.name.indexOf("Ceiling") !== -1) {
            return;
        }

        if (child.name.startsWith("axo_Slope")) {
            var slopeParse = child.name.split('_');
            var wallId = slopeParse[2] - 0;
            var directCoating = (slopeParse[3] === 'Direct');

            if (entity && entity.id !== wallId) {
                return;
            }

            // We do not put any color on tops and bottoms and on walls not in a room
            var wall = this.savane.getDeepChild(wallId);
            if (!wall) {
                return;
            }

            var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);
            coatings = coatings.filter(function(item) {
                return item.hangType === Savane.Coating.HangType.slopeDirect || item.hangType === Savane.Coating.HangType.slopeUndirect;
            })
            if ((coatings !== null) && (coatings.length > 0)) {
                for (var i = 0; i < coatings.length; i++) {
                    if (child.coatingId === coatings[i].coatingId) {
                        continue;
                    }

                    if ((coatings[i].hangType === Savane.Coating.HangType.slopeDirect && directCoating) || (coatings[i].hangType === Savane.Coating.HangType.slopeUndirect && !directCoating)) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                        child.coatingId = coatings[i].coatingId;
                        break;
                    }
                }
            }
            else {
                //Set material
                child.material = Material.default(new THREE.Color(0x505050).convertSRGBToLinear(), null);
            }
        } else if (child.name.startsWith("axo_Cornice")) {
            var corniceParse = child.name.split('_');
            var wallId = corniceParse[2] - 0;
            var directCoating = (corniceParse[4] === 'Direct' || corniceParse[5] === 'Direct');

            if (entity && entity.id !== wallId) {
                return;
            }

            // We do not put any color on tops and bottoms and on walls not in a room
            var wall = this.savane.getDeepChild(wallId);
            if (!wall) {
                return;
            }

            var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);
            coatings = coatings.filter(function(item) {
                return item.hangType === Savane.Coating.HangType.corniceDirect || item.hangType === Savane.Coating.HangType.corniceUndirect;
            })
            if ((coatings !== null) && (coatings.length > 0)) {
                for (var i = 0; i < coatings.length; i++) {
                    if (child.coatingId === coatings[i].coatingId) {
                        continue;
                    }

                    if ((coatings[i].hangType === Savane.Coating.HangType.corniceDirect && directCoating) || (coatings[i].hangType === Savane.Coating.HangType.corniceUndirect && !directCoating)) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                        child.coatingId = coatings[i].coatingId;
                        break;
                    }
                }
            }
            else {
                //Set material
                child.material = Material.default(new THREE.Color(0xFFFFFF).convertSRGBToLinear(), null);
            }
        } else {
            //set axo color to white
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    child.material[i].color = new THREE.Color(0xffffff);
                }
            } else {
                child.material.color = new THREE.Color(0xffffff);
            }
        }
    }

    _createGlass(child, joinery) {
        // 8 is glass
        // 7 is special glass with deformation
        if (joinery.frosted) {
            // Joinery with frosted glass
            this._createCubeCamera(child, 1024);
            child.cubeCamera.renderTarget.texture.mapping = THREE.CubeRefractionMapping;
            for (var i = 0; i < child.material.length; ++i) {
                var material = child.material[i];
                material.envMap = child.cubeCamera.renderTarget.texture;
                if (material.name === "8" || material.name === "7") {
                    material.color = new THREE.Color(1, 1, 1);
                    material.transparent = false;
                    material.alwaysTransparent = true;
                    material.refractionRatio = 1.0 / 1.5;
                    var normalMap = new Image();
                    normalMap.src = frosted;
                    material.normalMap = new THREE.TextureLoader().load(normalMap.src);
                    material.normalMap.wrapS = THREE.RepeatWrapping;
                    material.normalMap.wrapT = THREE.RepeatWrapping;
                    material.normalMap.repeat.set(6, 6);
                    material.normalScale.set(1.5, 1.5);
                }
            }
        }
        else {
            child.geometry.groups = child.geometry.groups.filter(function(item) {
                if (child.material[item.materialIndex].name === "8" || child.material[item.materialIndex].name === "7") {
                    var geometry = new THREE.BufferGeometry();
                    var vertices = child.geometry.getAttribute('position').clone();
                    vertices.array = vertices.array.slice(item.start * vertices.itemSize, (item.start + item.count) * vertices.itemSize);
                    vertices.count = item.count;
                    geometry.setAttribute('position', vertices);

                    var normals = child.geometry.getAttribute('normal').clone();
                    normals.array = normals.array.slice(item.start * normals.itemSize, (item.start + item.count) * normals.itemSize);
                    normals.count = item.count;
                    geometry.setAttribute('normal', normals);

                    child.material[item.materialIndex].alwaysTransparent = true;
                    var material = Material.clone(child.material[item.materialIndex], this.scene);
                    var mesh = new THREE.Mesh(geometry, material);
                    this._setTransparencyCallback(mesh);
                    mesh.name = child.name;
                    material.color = new THREE.Color(0xffffff);
                    material.transparent = true;
                    material.reflectivity = 0.15;
                    material.opacity = 0.05;
                    material.isGlass = true;
                    material.alwaysTransparent = true;
                    mesh.castShadow = false;
                    mesh.isGlass = true;
                    if (material.name === "7") {
                        this._createCubeCamera(mesh, 1024);
                        material.transparent = false;
                        material.reflectivity = 1;
                        material.refractionRatio = 1.0 / 1.5;
                        material.envMap = mesh.cubeCamera.renderTarget.texture;
                        mesh.cubeCamera.renderTarget.texture.mapping = THREE.CubeRefractionMapping;
                    }

                    child.add(mesh);
                    return false;
                }
                return true;
            }.bind(this));
        }
    }

    _createMirror(child, joinery, options) {
        // 9 is mirror
        // remove mirror geometry from current child

        if (!this.scene.settings.mirrors) {
            return;
        }

        child.geometry.groups = child.geometry.groups.filter(function(item) {
            if (!options.cuisinella && child.material[item.materialIndex].name === "9" ||
                ((options.cuisinella && options.mirror) && (child.material[item.materialIndex].name === "1" || child.material[item.materialIndex].name === "2"))) { // create a new mirror child
                var geometry = new THREE.BufferGeometry();
                var vertices = child.geometry.getAttribute('position').clone();
                vertices.array = vertices.array.slice(item.start * vertices.itemSize, (item.start + item.count) * vertices.itemSize);
                vertices.count = item.count;
                geometry.setAttribute('position', vertices);

                var normals = child.geometry.getAttribute('normal').clone();
                normals.array = normals.array.slice(item.start * normals.itemSize, (item.start + item.count) * normals.itemSize);
                normals.count = item.count;
                geometry.setAttribute('normal', normals);

                geometry.computeBoundingBox();

                var center = new THREE.Vector3();
                geometry.boundingBox.getCenter(center);
                var wNormal = joinery.parent.normal;
                var wCenter = joinery.parent.center;
                var plane = new THREE.Plane().setFromNormalAndCoplanarPoint(
                    new THREE.Vector3(wNormal[0], wNormal[1], wNormal[2]),
                    new THREE.Vector3(wCenter[0] / 100, wCenter[1] / 100, 0)
                );
                var project = new THREE.Vector3();
                plane.projectPoint(center, project);
                var normal = new THREE.Vector3().subVectors(center, project).normalize();
                var Y = normal.clone();
                var Z = new THREE.Vector3(0, 0, 1);
                var X = new THREE.Vector3().crossVectors(Y, Z);
                var basis = new THREE.Matrix4().makeBasis(X, Y, Z).setPosition(center).invert();
                for (var k = 0; k < vertices.array.length; k += 3) {
                    var v = new THREE.Vector3(vertices.array[k], vertices.array[k + 1], vertices.array[k + 2]);
                    v.applyMatrix4(basis);
                    vertices.array[k] = v.x;
                    vertices.array[k + 1] = v.y;
                    vertices.array[k + 2] = v.z;
                }

                geometry.computeBoundingBox();
                var size = new THREE.Vector3();
                geometry.boundingBox.getSize(size);
                geometry.dispose();
                geometry = new THREE.PlaneGeometry(size.x, size.z);
                var mirror = new THREE.Reflector(geometry, {
                    textureWidth: window.innerWidth,
                    textureHeight: window.innerHeight,
                    disableReflectors: true
                });
                mirror.name = child.name;
                var rotation = new THREE.Matrix4().makeRotationX(-Math.PI / 2);
                var mirrorBasis = new THREE.Matrix4().makeBasis(X, Y, Z).multiply(rotation);
                mirror.quaternion.setFromRotationMatrix(mirrorBasis);
                mirror.position.copy(center);
                mirror.position.addScaledVector(normal, size.y > 0.001 ? size.y / 2 + 0.001 : 0.001);
                this.mirrors.push({ object: mirror, entity: joinery });
                this.group.add(mirror);
            }

            return true;
        }.bind(this));
    }

    _handleJoinery(child, entity) {
        if (child.name.indexOf("Joinery") === - 1) {
            return;
        }

        var joineryParse = child.name.split('_');
        var id = (child.name.startsWith("axo") ? joineryParse[3] - 0 : joineryParse[2] - 0);
        var joinery = this.savane.getDeepChild(id);
        if (!joinery) {
            return;
        }

        if (entity && entity.id !== id) {
            return;
        }

        if (child.isGlass) return;

        var options = Material.joineryMaterialFromMaterialType(joinery.materialType);

        if (!joinery.model) {
            joinery.model = child;
        }

        var coatings = joinery.getComponents(Savane.ComponentConstants.ComponentType.Coating);
        if (options.coating) {
            // multimaterial coating
            Material.setMultiMaterial(child, options.coating, 1, this.scene, true, function() {
                if (Array.isArray(child.material)) {
                    this._createGlass(child, joinery);
                    this._createMirror(child, joinery, options);

                    if ((coatings !== null) && (coatings.length !== 0)) {
                        // Assign material to mesh
                        Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false);
                    }
                }
                else {
                    if ((coatings !== null) && (coatings.length !== 0)) {
                        // Assign material to mesh
                        Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false);
                    }
                }
                this._applyJoineryHoldout(joinery, child);
            }.bind(this));
        } else if ((coatings !== null) && (coatings.length !== 0)) {
            // Assign material to mesh
            Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false, function() {
                this._applyJoineryHoldout(joinery, child);
            });
        } else {
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    child.material[i].color = new THREE.Color(options.color).convertSRGBToLinear();
                    child.material[i].reflectivity = options.reflectivity;
                }

                this._createGlass(child, joinery);
                this._createMirror(child, joinery, options);
            } else {
                child.material.color = new THREE.Color(options.color).convertSRGBToLinear();
                child.material.reflectivity = options.reflectivity;
            }
            this._applyJoineryHoldout(joinery, child);
        }
    }

    _applyJoineryHoldout(joinery, child) {
        if (joinery.holdout) {
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    var name = child.material[i].name;
                    if (name === "8" || name === "7") continue;
                    child.material[i] = holdoutMaterial.clone();
                    child.material[i].name = name;
                }
            } else {
                var name = child.material.name;
                child.material = holdoutMaterial.clone();
                child.material.name = name;
            }
        }
    }

    _handleWall(child, entity) {
        if (!child.name.startsWith("Wall")) {
            return;
        }

        // add node to wall
        var wallParse = child.name.split('_');
        var wallId = wallParse[1] - 0;

        var hangType = Savane.Coating.HangType.wallDirect;
        if (wallParse[3] === 'Indirect') {
            hangType = Savane.Coating.HangType.wallUndirect;
        } else if (wallParse[2] === 'Top') {
            hangType = Savane.Coating.HangType.wallTop;
        } else if (wallParse[2] === 'Bottom') {
                hangType = Savane.Coating.HangType.wallBottom;
        } else if (wallParse[2] === 'RightSide') {
            hangType = Savane.Coating.HangType.wallRightSide;
        } else if (wallParse[2] === 'LeftSide') {
            hangType = Savane.Coating.HangType.wallLeftSide;
        }

        if (entity && entity.id !== wallId) {
            return;
        }

        var wall = this.savane.getDeepChild(wallId);
        if (!wall) return;

        if (wall.holdout) {
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    var name = child.material[i].name;
                    child.material[i] = holdoutMaterial.clone();
                    child.material[i].name = name;
                    child.coatingId = undefined;
                }
            } else {
                var name = child.material.name;
                child.material = holdoutMaterial.clone();
                child.material.name = name;
                child.coatingId = undefined;
            }
            return;
        }

        var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);
        coatings = coatings.filter(function(item) {
            return item.hangType === hangType;
        });

        // Apply identified coating
        if ((coatings !== null) && (coatings.length > 0)) {
            for (var i = 0; i < coatings.length; i++) {
                if (child.coatingId === coatings[i].coatingId) {
                    continue;
                }

                if (coatings[i].hangType === hangType) {
                    if (coatings[i].floorGeneratorSettings) {
                        if (coatings[i].floorGeneratorSettings.jointCoating) {
                            child.material = Material.default(new THREE.Color(coatings[i].floorGeneratorSettings.jointCoating.colors[0].realColor).convertSRGBToLinear(), null, {});
                            child.coatingId = coatings[i].floorGeneratorSettings.jointCoating.coatingId;
                        } else {
                            child.material = Material.default(new THREE.Color(0xffffff).convertSRGBToLinear(), null, {});
                            child.coatingId = undefined;
                        }
                    } else {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                        child.coatingId = coatings[i].coatingId;
                    }
                    break;
                }
            }
        }
        else {
            //Set material
            child.material = Material.default(new THREE.Color(0x505050).convertSRGBToLinear(), null, {

            });
            child.coatingId = undefined;
        }
    }

    _handleCeiling(child, entity) {
        if (child.name.indexOf("Ceiling") === -1) {
            return;
        }

        //add node to wall
        var ceilingParse = child.name.split('_');
        var index = 1;
        if (child.name.startsWith("axo")) {
            index++;
        }
        var roomId = Math.abs(ceilingParse[index] - 0);
        var room = this.savane.getDeepChild(roomId);
        if (!room) {
            return;
        }

        if (entity && entity.id !== roomId) {
            return;
        }

        if (room.isPool) {
            child.material = Material.default(new THREE.Color(0x39AEE1), null);
            return;
        }

        var coatings = room.getComponents(Savane.ComponentConstants.ComponentType.Coating);
        var found = false;

        if (coatings !== null) {
            // Assign material to mesh
            for (var i = 0; i < coatings.length; i++) {
                if (coatings[i].hangType === Savane.Coating.HangType.ceiling) {
                    Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false, function() {
                        if (Array.isArray(child.material)) {
                            for (var i = 0; i < child.material.length; ++i) {
                                child.material[i].side = THREE.FrontSide;
                            }
                        } else {
                            child.material.side = THREE.FrontSide;
                        }
                    });
                    found = true;
                    child.coatingId = coatings[i].coatingId;
                    break;
                }
            }
        }

        // Coating not found, assign default material
        if (!found) {
            // Default color assignment when no coating
            child.material = Material.default(new THREE.Color(0xffffff), null);
        }
    }

    _handleStair(child, entity) {
        if (child.name.indexOf("Stair") === -1) {
            return;
        }

        var stairParse = child.name.split('_');
        var id = stairParse[1] - 0;
        if (child.name.startsWith("axo")) {
            id = stairParse[2] - 0;
        }
        var stair = this.savane.getDeepChild(id);
        if (!stair) {
            return;
        }

        if (entity && entity.id !== id) {
            return;
        }

        var stepColor = 0x542B17;
        var counterStepColor = 0xFFFFFF;
        var limonColor = 0x542B17;
        switch (stair.materialType) {
            case 0:
                counterStepColor = 0x542B17;
                break;

            case 1:
                counterStepColor = 0x542B17;
                break;

            case 2:
                break;

            case 3:
                break;

            case 4:
                counterStepColor = 0x542B17;
                limonColor = 0x0;
                break;

            case 5:
                stepColor = 0x0;
                break;

            case 6:
                stepColor = 0xCF9B6B;
                counterStepColor = 0xCF9B6B;
                limonColor = 0xCF9B6B;
                break;

            case 7:
                stepColor = 0xCF9B6B;
                counterStepColor = 0xCF9B6B;
                limonColor = 0xCF9B6B;
                break;

            case 8:
                stepColor = 0xCF9B6B;
                limonColor = 0xCF9B6B;
                break;

            case 9:
                stepColor = 0xCF9B6B;
                limonColor = 0xCF9B6B;
                break;

            case 10:
                stepColor = 0xCF9B6B;
                counterStepColor = 0xCF9B6B;
                limonColor = 0x0;
                break;

            case 11:
                stepColor = 0x0;
                limonColor = 0xCF9B6B;
                break;

            case 12:
                stepColor = 0x0;
                limonColor = 0x0;
                break;
            case 13:
                stepColor = 0xFFFFFF;
                limonColor = 0xFFFFFF;
                break;

            case 14:
                stepColor = 0xCF9B6B;
                counterStepColor = 0xCF9B6B;
                limonColor = 0xFFFFFF;
                break;
        }

        if (Array.isArray(child.material)) {
            for (var i = 0; i < child.material.length; ++i) {
                switch (child.material[i].name) {
                    case "1":
                        child.material[i].color = new THREE.Color(stepColor).convertSRGBToLinear();
                        break;

                    case "2":
                        child.material[i].color = new THREE.Color(counterStepColor).convertSRGBToLinear();
                        break;

                    case "3":
                        child.material[i].color = new THREE.Color(limonColor).convertSRGBToLinear();
                        break;
                }
            }
        }
        else {
            switch (child.material.name) {
                case "1":
                    child.material.color = new THREE.Color(stepColor).convertSRGBToLinear();
                    break;

                case "2":
                    child.material.color = new THREE.Color(counterStepColor).convertSRGBToLinear();
                    break;

                case "3":
                    child.material.color = new THREE.Color(limonColor).convertSRGBToLinear();
                    break;
            }
        }

        var coatings = stair.getComponents(Savane.ComponentConstants.ComponentType.Coating);

        if ((coatings !== null) && (coatings.length !== 0)) {
            // Assign material to mesh
            for (var i = 0 ; i < coatings.length ; i++) {
                Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
            }
        }
    }

    _handleStairGuardsRail(child, entity) {
        if (!child.name.startsWith("StairGuardsRail")) {
            return;
        }

        var stairParse = child.name.split('_');
        var id = stairParse[1] - 0;
        var stair = this.savane.getDeepChild(id);
        if (!stair) {
            return;
        }

        if (entity && entity.id !== id) {
            return;
        }

        // Bois clair
        var material = {
            color: 0xCF9B6B
        }
        switch (stair.rampMaterialType) {
            // Bois foncé
            case 1:
                material.color = 0x542B17;
                break;

            // Blanc
            case 2:
            // Chrome
            case 4:
            // Verre
            case 5:
                material.color = 0xffffff;
                break;

            // Noir
            case 3:
                material.color = 0x0;
                break;
        }

        material.side = this.scene.settings.interactiveProject ? THREE.FrontSide : THREE.DoubleSide;
        var name = child.material.name;
        child.material = Material.default(new THREE.Color(material.color).convertSRGBToLinear(), null, material);
        child.material.name = name;

        switch (stair.rampMaterialType) {
            // Chrome
            case 4:
                child.material.reflectivity = 1.0;
                this._createCubeCamera(child, 256);
                break;

            // Verre
            case 5:
                child.material.transparent = true;
                child.material.opacity = 0.1;
                child.material.reflectivity = 0.15;
                child.castShadow = false;
                break;
        }

        var coatings = stair.getComponents(Savane.ComponentConstants.ComponentType.Coating);

        if ((coatings !== null) && (coatings.length !== 0)) {
            // Assign material to mesh
            for (var i = 0 ; i < coatings.length ; i++) {
                Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
            }
        }
    }

    _handlePlinth(child, entity) {
        if (!child.name.startsWith("Plinth")) {
            return;
        }

        var plinthParse = child.name.split('_');
        var wallId = plinthParse[1] - 0;
        var directCoating = (plinthParse[3] === 'Direct');
        var roomId = plinthParse[4] - 0;
        var wall = this.savane.getDeepChild(wallId);
        var room = this.savane.getDeepChild(roomId);
        if (!room) {
            return;
        }

        if (entity && entity.id !== roomId && entity.id !== wallId) {
            return;
        }

        // Handle Coating with wall plinth hang type
        if (wall !== null) {
            var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);
            coatings = coatings.filter(function(item) {
                return item.hangType === Savane.Coating.HangType.plinthDirect || item.hangType === Savane.Coating.HangType.plinthUndirect;
            });
            if ((coatings !== null) && (coatings.length > 0)) {
                for (var i = 0; i < coatings.length; i++) {
                    if ((coatings[i].hangType === Savane.Coating.HangType.plinthDirect && directCoating) || (coatings[i].hangType === Savane.Coating.HangType.plinthUndirect && !directCoating)) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                        child.coatingId = coatings[i].coatingId;
                        return;
                    }
                }
            }
        }

        switch (room.plinthsMaterialType) {
            // White
            case 0:
                child.material = Material.default(new THREE.Color(0xffffff), null, {

                });
                break;

            // Like room floor
            case 1:
                var coatings = room.getComponents(Savane.ComponentConstants.ComponentType.Coating);
                var hangType = Savane.Coating.HangType.floor;
                var found = false;
                for (var i = 0; i < coatings.length; i++) {

                    if (coatings[i].hangType === hangType) {
                        if (!coatings[i].floorGeneratorSettings) {
                            Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                            child.coatingId = coatings[i].coatingId;
                        }
                        if (coatings[i].floorGeneratorSettings && coatings[i].floorGeneratorSettings.jointCoating) {
                            child.material = Material.default(new THREE.Color(coatings[i].floorGeneratorSettings.jointCoating.colors[0].realColor).convertSRGBToLinear(), null, {

                            });
                            child.coatingId = coatings[i].floorGeneratorSettings.jointCoating.coatingId;
                        }
                        found = true;
                        break;
                    }
                }
                if (found == false) {
                    // Default color assignment when no coating
                    child.material = Material.default(new THREE.Color(0xffffff), null, {

                    });
                }
                break;

            // Like wall
            case 2:
                if (wall !== null) {
                    var coatings = wall.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                    if ((coatings !== null) && (coatings.length > 0)) {
                        for (var i = 0; i < coatings.length; i++) {
                            if (child.coatingId === coatings[i].coatingId) {
                                continue;
                            }

                            if ((coatings[i].hangType === Savane.Coating.HangType.wallDirect && directCoating) || (coatings[i].hangType === Savane.Coating.HangType.wallUndirect && !directCoating)) {
                                Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                                child.coatingId = coatings[i].coatingId;
                                break;
                            }
                        }
                    }
                    else {
                        //Set material
                        child.material = Material.default(new THREE.Color(0xffffff), null, {

                        });
                    }
                }
                break;
        }
    }

    _handleTechnicalElementPlinth(child, entity) {
        if (!child.name.startsWith("TechnicalElementPlinth")) {
            return;
        }

        child.material.color = new THREE.Color(0xffffff);

        var plinthParse = child.name.split('_');
        var techElementId = plinthParse[2] - 0;
        var techElement = this.savane.getDeepChild(techElementId);
        if (!techElement) {
            return;
        }

        if (entity && entity.id !== techElementId) {
            return;
        }

        var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);
        if ((coatings !== null) && (coatings.length > 0)) {
            for (var i = 0 ; i < coatings.length ; i++) {
                if (coatings[i].hangType === Savane.Coating.HangType.plinthDirect) {
                    Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                    return;
                }
            }
        }

        var room = Savane.roomManager.getRoomAtPosition(techElement.position, techElement.parent);
        if (!room) {
            return;
        }

        switch (room.plinthsMaterialType) {
            // White
            case 0:
                child.material = Material.default(new THREE.Color(0xffffff), null, { });
                break;

            // Like room floor
            case 1:
                var coatings = room.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                if ((coatings !== null) && (coatings.length !== 0)) {
                    // Assign material to mesh
                    Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false);
                }
                else {
                    // Default color assignment when no coating
                    child.material = Material.default(new THREE.Color(0xffffff), null, { });
                }
                break;

            // Like wall
            case 2:
                var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                if ((coatings !== null) && (coatings.length > 0)) {
                    Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false);
                }
                else {
                    // Default color assignment when no coating
                    child.material = Material.default(new THREE.Color(0xffffff), null, { });
                }
                break;
        }
    }

    _handleTechnicalElement(child, entity) {
        var techElementParse = child.name.split('_');
        var index = techElementParse.indexOf("TechnicalElement");

        if (index === -1) {
            return;
        }

        var type = techElementParse[index + 1];
        var id = techElementParse[index + 2] - 0;
        var techElement = this.savane.getDeepChild(id);
        var scene = this.scene;
        if (!techElement) {
            return;
        }

        if (techElement.holdout) {
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    var name = child.material[i].name;
                    child.material[i] = holdoutMaterial.clone();
                    child.material[i].name = name;
                }
            } else {
                var name = child.material.name;
                child.material = holdoutMaterial.clone();
                child.material.name = name;
            }
            return;
        }

        if (entity && entity.id !== id) {
            return;
        }

        var material = {
            color: 0xffffff,

        };

        var assignColor = true;

        switch (type) {
            case "Pole":
            case "Beam":
            case "Frame":
            if (techElement.isTechnicalElementObjectEntity()) {
                var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                assignColor = false;

                if ((coatings !== null) && (coatings.length !== 0)) {
                    // Assign material to mesh
                    for(var i = 0 ; i < coatings.length ; i++) {
                        if (coatings[i].hangType === Savane.Coating.HangType.technicalElement || coatings[i].hangType === Savane.Coating.HangType.usemtl) {
                            Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                        }
                    }
                }
                else {
                    switch (techElement.materialType) {
                        // Neutre
                        case 0:
                            Material.setMaterialFromCoating(child, { _id: '5efde7003bf5f472eec2e35a' }, false, 1, this.scene, false);
                            break;

                        // Bois sombre
                        case 1:
                            Material.setMaterialFromCoating(child, { _id: '5efde656739226729d269a21' }, false, 1, this.scene, false);
                            break;

                        // Bois clair
                        case 2:
                            Material.setMaterialFromCoating(child, { _id: '5efde5fef2219b72a937d001' }, false, 1, this.scene, false);
                            break;

                        // Bois peint blanc
                        case 3:
                            Material.setMaterialFromCoating(child, { _id: '5efde5a31ca0ff72b4b0e2e6' }, false, 1, this.scene, false);
                            break;

                        // Bois usé
                        case 4:
                            Material.setMaterialFromCoating(child, { _id: '5efde53e9a372172a32f48a9' }, false, 1, this.scene, false);
                            break;

                        // Métal noir
                        case 5:
                            Material.setMaterialFromCoating(child, { _id: '5efde4dab25cfd72de0115c3' }, false, 1, this.scene, false);
                            break;

                        // Béton
                        case 6:
                            Material.setMaterialFromCoating(child, { _id: '5efde3efdf645372975fe02d' }, false, 1, this.scene, false);
                            break;

                        // Blanc
                        case 7:
                            Material.setMaterialFromCoating(child, { _id: '5efde47465cc93730671f88c' }, false, 1, this.scene, false);
                            break;

                        // Bois usé terne
                        case 8:
                            Material.setMaterialFromCoating(child, { _id: '60adfb4dbfbf5b3f21001d10' }, false, 1, this.scene, false);
                            break;

                        // Bois usé noeuds
                        case 9:
                            Material.setMaterialFromCoating(child, { _id: '60adfd59f961d43efd54b5bc' }, false, 1, this.scene, false);
                            break;

                        // Bois usé fissure
                        case 10:
                            Material.setMaterialFromCoating(child, { _id: '60adfe84d8bb9e3f324512a1' }, false, 1, this.scene, false);
                            break;

                        // Bois usé clair
                        case 11:
                            Material.setMaterialFromCoating(child, { _id: '60adff25fe46fa3ee5781ea6' }, false, 1, this.scene, false);
                            break;

                        // Bois brosse fonce
                        case 12:
                            Material.setMaterialFromCoating(child, { _id: '60ae0432dbafe33f0851f413' }, false, 1, this.scene, false);
                            break;

                        // Bois moyen
                        case 13:
                            Material.setMaterialFromCoating(child, { _id: '60af63ae96834c567a117d07' }, false, 1, this.scene, false);
                            break;

                        case 14:
                            Material.setMaterialFromCoating(child, { _id: '60ae0432dbafe33f0851f413' }, false, 1, this.scene, false);
                            break;

                        case 15:
                            Material.setMaterialFromCoating(child, { _id: '65e877a8d90439eef6cd8a5a' }, false, 1, this.scene, false);
                            break;
                    }
                }
            }
            else {
                var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                if ((coatings !== null) && (coatings.length > 0)) {
                    Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false);
                    child.coatingId = coatings[0].coatingId;
                    assignColor = false;
                } else {
                    // apply multi texture MTL
                    Material.setMaterialFromDecoratedWallsElement(child, techElement, this.scene, false, function() {
                        // remove glass for fix transparency problems
                        child.geometry.groups = child.geometry.groups.filter(function(item) {
                            if (child.material[item.materialIndex].transparent === true) {
                                child.material[item.materialIndex].alwaysTransparent = true;
                            }
                            return true;
                        });
                    });
                    assignColor = false;
                }
            }
            break;

            case "SpecialWall":
                var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                if ((coatings !== null) && (coatings.length > 0)) {
                    for (var i = 0 ; i < coatings.length ; i++) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false);
                    }
                    assignColor = false;
                } else {
                    // apply multi texture MTL
                    Material.setMaterialFromDecoratedWallsElement(child, techElement, this.scene, false, function() {
                        // remove glass for fix transparency problems
                        child.geometry.groups = child.geometry.groups.filter(function(item) {
                            if (child.material[item.materialIndex].transparent === true) {
                                child.material[item.materialIndex].alwaysTransparent = true;
                            }
                            return true;
                        });
                    });
                    assignColor = false;
                }
                break;

            case "Radiator":
                var options = Material.radiatorMaterialFromMaterialType(techElement.materialType);
                var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                if ((coatings !== null) && (coatings.length !== 0)) {
                    // Assign material to mesh
                    Material.setMaterialFromCoating(child, coatings[0], true, undefined, this.scene, false);
                    assignColor = false;
                } else {
                    // apply multi texture MTL
                    Material.setMultiMaterial(child, options.coating, 1, this.scene, true, function() {
                        if (techElement.materialType === 3) {
                            this._createCubeCamera(child, 256);
                            if (Array.isArray(child.material)) {
                                for (var i = 0; i < child.material.length; ++i) {
                                    child.material[i].reflectivity = 1;
                                    child.material[i].envMap = child.cubeCamera.renderTarget.texture;
                                }
                            } else {
                                child.material.reflectivity = 1;
                                child.material.envMap = child.cubeCamera.renderTarget.texture;
                            }
                        }
                    }.bind(this));
                    assignColor = false;
                }
                break;

            case "SpotLight":
                var intensity = 0;

                switch (techElement.intensity) {
                    case Savane.SceneConstants.SpotLightIntensity.lowPower:
                        intensity = 300;
                        break;

                    case Savane.SceneConstants.SpotLightIntensity.mediumPower:
                        intensity = 600;
                        break;

                    case Savane.SceneConstants.SpotLightIntensity.highPower:
                        intensity = 1000;
                        break;
                }

                if (intensity !== 0) {
                    // Extract light color with RGB model in a single color
                    var color = new THREE.Color(0xffffff);
                    var light = new THREE.SpotLight(color, intensity, 0, Math.PI / 2.1, 0.2, 2);
                    // Position the light itself
                    light.position.x = techElement.position[0] / 100;
                    light.position.y = techElement.position[1] / 100;
                    light.position.z = ((techElement.position[2] - (techElement.height / 2)) / 100);

                    // Create the light target
                    var target = new THREE.Object3D();
                    target.position.x = light.position.x;
                    target.position.y = light.position.y;
                    target.position.z = light.position.z - 10.0;
                    light.target = target;

                    // Temporary light helper to remove
                    // var lightHelper   = new THREE.CameraHelper(light.shadow.camera);
                    // END

                    // This object creates shadows - disable otherwise it can crash
                    light.castShadow = false;

                    this.group.add(light.target);
                    // Temporary light helper to remove
                    // this.group.add(lightHelper);
                    // END
                    this.group.add(light);
                }
                break;

            case "Rosette":
            case "WallDecoration":
            case "CeilingBox":
                var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                if ((coatings !== null) && (coatings.length !== 0)) {
                    // Assign material to mesh
                    for (var i = 0 ; i < coatings.length ; i++) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, scene, false);
                    }
                    assignColor = false;
                }
                break;

            case "Fireplace":
            case "Stove":
            case "Boiler":
            case "SwitchBoard":
            case "AirConditioner":
                Material.setMaterialFromTechnicalElement(child, techElement, scene, false, function() {
                    // remove glass for fix transparency problems
                    child.geometry.groups = child.geometry.groups.filter(function(item) {
                        if (child.material[item.materialIndex].transparent === true) {
                            child.material[item.materialIndex].alwaysTransparent = true;
                        }
                        if (child.material[item.materialIndex].opacity === 0) {
                            return false;
                        }
                        return true;
                    });

                    var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                    for (var i = 0 ; i < coatings.length ; i++) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, scene, false);
                    }
                });
                assignColor = false;
                break;

            case "GuardRail":
                Material.setMaterialFromDecoratedWallsElement(child, techElement, scene, false, function() {
                    // remove glass for fix transparency problems
                    child.geometry.groups = child.geometry.groups.filter(function(item) {
                        if (child.material[item.materialIndex].transparent === true) {
                            child.material[item.materialIndex].alwaysTransparent = true;
                        }
                        if (child.material[item.materialIndex].opacity === 0) {
                            return false;
                        }
                        return true;
                    });

                    var coatings = techElement.getComponents(Savane.ComponentConstants.ComponentType.Coating);

                    for (var i = 0 ; i < coatings.length ; i++) {
                        Material.setMaterialFromCoating(child, coatings[i], true, undefined, scene, false);
                    }
                });
                assignColor = false;
                break;
        }

        if (assignColor) {
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    var name = child.material[i].name;
                    child.material[i] = Material.default(new THREE.Color(material.color).convertSRGBToLinear(), null, material);
                    child.material[i].name = name;
                }
            } else {
                var name = child.material.name;
                child.material = Material.default(new THREE.Color(material.color).convertSRGBToLinear(), null, material);
                child.material.name = name;
            }
        }
    }

    _handlePoolEdge(child, entity) {
        if (child.name.indexOf("PoolEdge") === -1) {
            return;
        }

        //add node to wall
        var PoolEdgeParse = child.name.split('_');
        var roomId = Math.abs(PoolEdgeParse[1] - 0);
        var room = this.savane.getDeepChild(roomId);
        if (!room) {
            return;
        }

        if (entity && entity.id !== roomId) {
            return;
        }

        var coatings = room.getComponents(Savane.ComponentConstants.ComponentType.Coating);
        if (coatings !== null) {
            // Assign material to mesh
            for (var i = 0; i < coatings.length; i++) {
                if (coatings[i].hangType === Savane.Coating.HangType.ceiling) {
                    Material.setMaterialFromCoating(child, coatings[i], true, undefined, this.scene, false, function() {
                        if (Array.isArray(child.material)) {
                            for (var i = 0; i < child.material.length; ++i) {
                                child.material[i].side = THREE.FrontSide;
                            }
                        } else {
                            child.material.side = THREE.FrontSide;
                        }
                    });
                    child.coatingId = coatings[i].coatingId;
                    break;
                }
            }
        }
    }

    _setTransparencyCallback(child) {
        var self = this;
        child.onBeforeRender = function(renderer, scene, camera, geometry, material, group) {
            if (material.alwaysTransparent) {
                return;
            }
            if (self.scene.hullTransparency === false) {
                if (!material.isGlass) {
                    material.transparent = false;
                } else {
                    material.opacity = 0.05;
                }
                material.needsUpdate = true;
                return;
            }
            if (camera.parent && camera.parent.type === "CubeCamera") return;

            var direction = new THREE.Vector3();
            camera.getWorldDirection(direction);
            var target = self.scene.defaultCamera.target;
            for (var i = 0; i < self.scene.planCameras.length; ++i) {
                if (camera === self.scene.planCameras[i].object) {
                    target = self.scene.planCameras[i].target;
                }
            }
            if (!target) return;
            var distanceToTarget = new THREE.Vector3().subVectors(target, camera.position).length();
            var center = new THREE.Vector3();
            if (!geometry.boundingBox) {
                geometry.computeBoundingBox();
            }
            geometry.boundingBox.getCenter(center);
            var distanceToCenter = new THREE.Vector3().subVectors(center, camera.position).length();
            if (distanceToTarget > distanceToCenter) {
                material.transparent = true;
                material.opacity = 0.05;
            } else {
                if (!material.isGlass) {
                    material.transparent = false;
                } else {
                    material.opacity = 0.05;
                }
            }
            material.needsUpdate = true;
        };
    }

    _processChild(child) {
        if (!this._entity) {
            if (child.isMesh) {
                var euler = new THREE.Euler(Math.PI * 0.5, 0, 0, 'XYZ');
                child.geometry.applyMatrix4(new THREE.Matrix4().makeRotationFromEuler(euler));
                child.geometry.applyMatrix4(new THREE.Matrix4().makeScale(0.01, 0.01, 0.01));
            }

            //hull creation - link elements to scene entities
            var entity = this.getEntityFromChild(child);
            if (entity) {
                if (this.scene.interactionWithItemAllowed(entity)) {
                    this.scene.planEntities.push(new WebglHullEntity(entity, this.scene, child, this, false));
                } else if (this.scene.itemSelectionAllowed(entity)) {
                    this.scene.planEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, true));
                } else {
                    this.scene.nonInteractivePlanEntities.push(new WebglHullEntity(entity, this.scene, child, this, false, true));
                }
            }
            //Enable shadow
            child.receiveShadow = true;
            child.castShadow = true;
        }

        this._handleAxo(child, this._entity);
        this._handleJoinery(child, this._entity);
        this._handleWall(child, this._entity);
        this._handleCeiling(child, this._entity);
        this._handleStair(child, this._entity);
        this._handleStairGuardsRail(child, this.entity);
        this._handlePlinth(child, this._entity);
        this._handleTechnicalElementPlinth(child, this._entity);
        this._handleTechnicalElement(child, this._entity);
        this._handlePoolEdge(child, this._entity);

        if (!this._entity) {
            if (child.isMesh) {
                this._setTransparencyCallback(child);
            }
        }

        if (!child.material && child.isMesh) {
            child.material = Material.default(new THREE.Color(0xffffff), null, {});
        }

        // apply customCoating
        for (var i = 0; i < this.savane.children.length; ++i) {
            var floor = this.savane.children[i];
            if (floor.entityType === Savane.SceneConstants.EntityType.Floor) {
                var name = child.name.replace(/_[a-fA-F0-9]{24}_\d+/, '');
                var custom = floor.getCustomCoating(name);
                if (custom) {
                    Material.setMaterialFromCoating(child, custom.coating, true, undefined, this.scene, false);
                    child.coatingId = custom.coating.coatingId;
                }
            }
        }

        // set envMap to material
        if (child.cubeCamera) {
            if (Array.isArray(child.material)) {
                for (var i = 0; i < child.material.length; ++i) {
                    child.material[i].envMap = child.cubeCamera.renderTarget.texture;
                }
            } else {
                child.material.envMap = child.cubeCamera.renderTarget.texture;
            }
        }
    }

}
